<template>
  <div class="my-informations app-my-wrap">
    <div class="app-my-title">
      インフォメーション
    </div>
     <div class="my-information-list-wrap">
        <div v-loading="tableIsLoading">
          <div class="app-list-wrap">
              <div class="app-list"
                v-for="(item, index) in
                  tableData"
                :key="index"
              >
              <br>
              <table width="100%" border=0 style="font-size:15px;line-height:1.5em;">
                <tr>
                  <td class="bg" width="35%">日時</td>
                  <td>{{item.time}}</td>
                </tr>
                <tr>
                  <td class="bg">カテゴリ</td>
                  <td>
                      <el-tag v-if="item.category === '0'" size="small" class="studypack-button-primary" type="primary">新規講座開講のお知らせ</el-tag>
                      <el-tag v-if="item.category === '1'" size="small" class="studypack-button-primary" type="primary">講座内容の訂正</el-tag>
                      <el-tag v-if="item.category === '2'" size="small" class="studypack-button-primary" type="primary">講座内容の追加</el-tag>
                      <el-tag v-if="item.category === '3'" size="small" class="studypack-button-primary" type="primary">講座内容の削除</el-tag>
                  </td>
                </tr>
                <tr>
                  <td class="bg">件名</td>
                  <td>
                    <router-link :to="`/mypage/information/${item.id}`">
                        {{item.title}}
                    </router-link>
                  </td>
                </tr>
              </table>

            </div>
          </div>
        </div>
     </div>
<!--
      <div class="my-information-list-wrap">
        <el-table
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            prop="time"
            label="日時"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="category"
            label="カテゴリ"
            width="200"
          >
            <template slot-scope="scope">
              <el-tag v-if="scope.row.category === '0'" size="small" class="studypack-button-primary" type="primary">新規講座開講のお知らせ</el-tag>
              <el-tag v-if="scope.row.category === '1'" size="small" class="studypack-button-primary" type="primary">講座内容の訂正</el-tag>
              <el-tag v-if="scope.row.category === '2'" size="small" class="studypack-button-primary" type="primary">講座内容の追加</el-tag>
              <el-tag v-if="scope.row.category === '3'" size="small" class="studypack-button-primary" type="primary">講座内容の削除</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="講座名"
          >
            <template slot-scope="scope">
              <router-link
                :to="`/mypage/information/${scope.row.id}`"
                :class="{'app-my-new': scope.row.isNew}"
              >
                {{scope.row.title}}
              </router-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            :page-size="listSize"
            :pager-count="7"
            layout="prev, pager, next"
            :total="listTotal"
            @current-change="getList"
          >
          </el-pagination>
      </div>
    </div>
    -->
  </div>
</template>

<script>
import request from '@/utils/request';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      tableData: [],
      listTotal: 0,
      listSize: 10,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList(page) {
      request('getInformationList', {
        page: page || 1,
        limit: this.listSize,
      }).then((res) => {
        const { count, courseInfoResponseResourceList: list } = res;

        this.listTotal = count;
        this.tableData = list.map((item) => ({
          id: item.infoId,
          courseId: item.courseId,
          time: dayjs(item.openStartTime).format('YYYY-MM-DD HH:mm:ss'),
          openStartTime: dayjs(item.openStartTime).format('YYYY-MM-DD'),
          openEndTime: dayjs(item.openEndTime).format('YYYY-MM-DD'),
          // isNew: true,
          category: item.infoType,
          title: item.infoName,
        }));
      });
    },
  },
};
</script>

<style lang="less" scoped>
// .my-informations {
//   .my-information-list {
//     .my-information-list-item {
//       margin-bottom: 10px;
//       padding-bottom: 10px;
//       border-bottom: 1px solid #ccc;

//       & > span {
//         display: inline-block;
//         text-overflow: ellipsis;
//         white-space: nowrap;
//         overflow: hidden;
//         margin-right: 5px;
//         font-size: 14px;
//       }

//       .my-information-list-item-time {
//         width: 140px;
//       }
//       .my-information-list-item-category {
//         width: 200px;
//       }
//       // .my-information-list-item-title {
//       //   display: inline-block;
//       //   text-overflow: ellipsis;
//       //   white-space: nowrap;
//       // }
//     }
//   }
// }
</style>
